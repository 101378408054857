import ServicesAuth from './servicesAuth'

class CartaoGatewayPropostaService extends ServicesAuth {
    constructor() {
        super({
            url: '/cartao',
            config: {
                baseURL: process.env.VUE_APP_API_CREDITO_GATEWAY_URL,
                timeout: 60 * 1000
            }
        })
    }

    getCartao(id_cartao) {
        return this.axios.get(`${this.url}/${id_cartao}`)
    }
}

export default new CartaoGatewayPropostaService()
