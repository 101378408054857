<template>
  <q-layout
    class="c-layout shadow-2 rounded-borders"
    ref="layout"
    view="lHh Lpr fff"
  >
    <q-header>
      <q-toolbar color="primary" elevated :inverted="$q.theme === 'ios'">
        <q-toolbar-title> Cartão Digital </q-toolbar-title>
      </q-toolbar>
    </q-header>
    <q-page-container>
      <div
        class="col"
        style="max-width: 1200px; margin-left: auto; margin-right: auto"
      >
        <div class="full-width">
          <div
            :class="`row bg-${
              $q.dark.isActive ? 'dark' : 'white'
            } q-pa-sm flex flex-center`"
          >
            <template v-if="loadingCartao">
              <q-card style="width: 470px; height: 295px">
                <q-skeleton width="100%" height="100%" />
              </q-card>
            </template>
            <template v-else>
              <template v-if="cartaoExiste">
                <div v-if="cartaoAtivo" class="col-12 col-md-6 q-pa-sm">
                  <q-cartao-digital
                    :id-cartao="id_cartao"
                    :loading-cartao="loadingCartao"
                    :empresa="empresa"
                    :style-dados-cartao="styleDadosCartao"
                    :nome-cliente="nomeCliente"
                    :data-cadastro="dataCadastro"
                    :numero-cartao-mascara="numeroCartaoMascara"
                    :numero-cartao="numeroCartao"
                  />
                </div>
                <div v-else class="col text-center q-py-lg">
                  <p class="text-h3 text-weight-medium">Atenção</p>
                  <p>Este cartão atualmente não está ativo.</p>
                </div>
              </template>
              <template v-else>
                <div class="col text-center q-py-lg">
                  <p class="text-h3 text-weight-medium">Atenção</p>
                  <p>
                    Não foi possivel encontrar o cartão!<br />Se o problema
                    persistir, entre em contato com a central!
                  </p>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </q-page-container>
  </q-layout>
</template>

<script>
import CartaoGatewayPropostaService from "@/services/cartaoGatewayProposta";
import QCartaoDigital from "@/components/shared/QCartaoDigital";
import { date } from "quasar";

export default {
  name: "CartaoDigital",

  components: {
    QCartaoDigital,
  },

  data() {
    return {
      id_cartao: null,
      cartaoExiste: false,
      cartao: {
        ativo: true,
        nome_abreviado: null,
        data_cadastro: null,
        mascara_cartao: null,
        numero_cartao: null,
      },
      loadingCartao: true,
    };
  },

  computed: {
    empresa() {
      return process.env.VUE_APP_EMPRESA;
    },

    nomeCliente() {
      return this.cartao.nome_abreviado;
    },

    dataCadastro() {
      return date.formatDate(this.cartao.data_cadastro, "MM/YYYY");
    },

    numeroCartaoMascara() {
      return this.cartao.mascara_cartao;
    },

    numeroCartao() {
      return this.cartao.numero_cartao;
    },

    cartaoAtivo() {
      return this.cartao.ativo;
    },
  },

  mounted() {
    this.id_cartao = this.$route.params.id_cartao;
    this.buscaDadosCartao();
  },

  methods: {
    buscaDadosCartao() {
      CartaoGatewayPropostaService.getCartao(this.id_cartao)
        .then(({ data }) => {
          this.cartao = {
            ativo: data.ativo,
            nome_abreviado: data.nome_abreviado,
            data_cadastro: data.cliente.data_cadastro,
            mascara_cartao: data.mascara_cartao,
            numero_cartao: data.numero_cartao,
          };
          this.cartaoExiste = true;
          this.loadingCartao = false;
        })
        .catch(() => {
          this.cartaoExiste = false;
          this.loadingCartao = false;
        });
    },
  },
};
</script>

<style></style>
